import React from "react";

export default function TextInput({
  style,
  id,
  type,
  name,
  value,
  required,
  placeholder,
  tabIndex,
  ariaLabel,
  handleChange,
}: {
  style?: React.CSSProperties;
  id: string;
  // there are additional types if needed in future, see w3schools.com for full list
  type:
    | "checkbox"
    | "email"
    | "password"
    | "number"
    | "search"
    | "tel"
    | "text"
    | "url";
  name: string;
  value: string;
  required?: boolean;
  placeholder?: string;
  tabIndex?: number;
  ariaLabel?: string;
  handleChange: (inputName: string, inputValue: string) => void;
}): JSX.Element {
  return (
    <input
      style={style}
      id={id}
      type={type}
      value={value}
      required={required || false}
      autoComplete="off"
      autoCapitalize="none"
      placeholder={placeholder}
      tabIndex={tabIndex || 0}
      aria-label={ariaLabel}
      onChange={(event) => handleChange(name, event.target.value)}
    />
  );
}
