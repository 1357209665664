import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { ArrowRightShort } from "@styled-icons/bootstrap/ArrowRightShort";
import { FluidImage, SilentAny } from "../utils/types";
import FootnoteText from "./FootnoteText";
import Spacer from "./Spacer";
import { Link } from "gatsby";

export const WhatWeDoCard = ({
  style,
  fluidImage,
  title,
  alt,
  body,
  to,
}: {
  style?: React.CSSProperties;
  fluidImage: SilentAny;
  alt: string;
  title: string;
  body: string;
  to: string;
}): JSX.Element => {
  return (
    <CardStyled
      style={{
        ...style,
      }}
      to={to}
      aria-label={`Link to the services page section ${title}`}
    >
      <div>
        <ImgStyled fluid={fluidImage} alt={alt} />
        <Spacer height={2} />
        <h4>{title}</h4>
        <Spacer />
        <FootnoteText>{body}</FootnoteText>
      </div>
      <Spacer />
      <ArrowRightShortStyled />
    </CardStyled>
  );
};
const CardStyled = styled(Link)`
  text-decoration: none;
  width: 100%;
  height: 100%;
  min-height: ${(props) => props.theme.screenSize.xs}px;
  padding: 2rem 1rem;
  background: ${(props) => props.theme.colours.card};
  border-radius: ${(props) => props.theme.borderRadius.card};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 0.2s ease-in;
  svg {
    transition: color 0.2s ease-in;
    color: ${(props) => props.theme.colours.foregroundPrimary};
  }
  &:hover,
  &:focus-visible {
    box-shadow: 0 0 10px ${(props) => props.theme.colours.shadow};
    svg {
      color: ${(props) => props.theme.colours.midBlue};
    }
  }
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    min-height: ${(props) => props.theme.screenSize.xs - 100}px;
  }
`;
// passing fluid prop to styled component here to stop tsc warning
const ImgStyled = styled(Img)<{ fluid: FluidImage }>`
  background: ${(props) => props.theme.colours.card};
  height: 104px;
  img {
    left: 0;
    right: 0;
    width: auto !important;
    margin: 0 auto;
  }
`;
const ArrowRightShortStyled = styled(ArrowRightShort)`
  margin-left: -12px; /* Accounts for spacing around path inside svg without reducing clickable area size */
  width: 3rem;
  height: 3rem;
`;
