import React, { MutableRefObject, useCallback, useEffect, useRef } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { graphql } from "gatsby";
import BlueRobotSvg from "../assets/images/blue-robot.svg";
import RobotArmSvg from "../assets/images/robot-arm.svg";
import PhoneSvg from "../assets/images/phone.svg";
import Cloud1Svg from "../assets/images/cloud-1.svg";
import Cloud2Svg from "../assets/images/cloud-2.svg";
import Cloud3Svg from "../assets/images/cloud-3.svg";
import Skyscraper1Svg from "../assets/images/skyscraper-1.svg";
import Skyscraper2Svg from "../assets/images/skyscraper-2.svg";
import Skyscraper3Svg from "../assets/images/skyscraper-3.svg";
import Skyscraper4Svg from "../assets/images/skyscraper-4.svg";
import MountainsSvg from "../assets/images/mountains.svg";
import StarsSvg from "../assets/images/stars.svg";
import { Project, SilentAny } from "../utils/types";
import ContactForm from "../components/ContactForm";
import { MainIndexPage } from "../components/MainStyles";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import { OurPartnersLogosSection } from "../components/OurPartnersLogos";
import styled from "styled-components";
import { MaxWidthContainer } from "../components/MaxWidthContainer";
import Spacer from "../components/Spacer";
import { WhatWeDoCard } from "../components/WhatWeDoCard";
import { ProjectCards } from "../components/ProjectCards";
import { FourColumnGrid } from "../components/GridLayouts";
import { TitleButton } from "../components/LinkStyles";
import { ArrowLink } from "../components/ArrowLink";

const HomePage = ({
  data,
  location,
}: {
  data: SilentAny;
  location: LocationState;
}): JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);

  // const { windowWidth, windowHeight } = useWindowDimensions();
  // const mobileScreen = windowWidth && windowWidth < 500;
  // const projectsCardInColumnTwoDelay = mobileScreen ? 0 : 250;

  const contactFormRef = useRef() as MutableRefObject<HTMLDivElement>;

  const onClickScroll = useCallback(() => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    // for a11y tab focus, we need to add ref.current.focus here
    // preventScroll maintains the smooth scrll whilst moving the tab focus
    contactFormRef.current.focus({ preventScroll: true });
  }, []);

  // /* Option 1: add classname and animate with className */

  // const [hasScrolled, setScrolled] = useState(false);

  // const onScroll = useCallback(() => {
  //   const heightScrolled = document.documentElement.scrollTop;

  //   const landingPagePercentageScrolled = windowHeight
  //     ? (heightScrolled / windowHeight) * 100
  //     : 0;
  //   const hasScolled = landingPagePercentageScrolled > 0;
  //   setScrolled(hasScolled);
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  // });

  // const upAnimation = hasScrolled ? "custom-animation-up " : "";
  // const leftAnimation = hasScrolled ? "custom-animation-left " : "";
  // const rigthAnimation = hasScrolled ? "custom-animation-right " : "";

  // /* Option 2: animate based on scroll top height. Seems slow and need to comment out  <ProjectCards /> map for some reason and would need to fix that issue */

  // const [landingPageScrollValue, setLandingPageScrollValue] = useState(0);

  // const onScroll = useCallback(() => {
  //   const heightScrolled = document.documentElement.scrollTop;

  //   const landingPagePercentageScrolled = windowHeight
  //     ? (heightScrolled / windowHeight) * 100
  //     : 0;
  //   setLandingPageScrollValue(landingPagePercentageScrolled / 5);
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  // });

  if (!data) {
    return <></>;
  }

  const title = "Home";

  const projects = data.projects.edges.map(
    (project: SilentAny) => project.node as Project
  );

  return (
    <Layout location={location} contactFormRef={contactFormRef}>
      <SEO title={title} />
      <MainIndexPage id="main" tabIndex={-1}>
        <title>{title}</title>
        <LandingPageContainer>
          <TitleTextContainer>
            <h1 tabIndex={0}>
              Digital problems
              <br />
              simple solutions
            </h1>
            <Spacer />
            <TitleButton onClick={onClickScroll}>Get in touch</TitleButton>
          </TitleTextContainer>
          <LandingPageBlueObject
            src={BlueRobotSvg}
            alt="A retro robot with a lightbulb on its head"
            // landingPageScrollValue={landingPageScrollValue}
            top={15}
            left={15}
            // data-aos="custom-animation-up"
            // className={upAnimation}
          />

          <LandingPageBlueObject
            src={RobotArmSvg}
            alt="A robotic claw"
            // landingPageScrollValue={landingPageScrollValue}
            top={5}
            left={40}
            // className={upAnimation}
          />
          <LandingPageBlueObject
            src={PhoneSvg}
            alt="An illustration of a mobile phone"
            // landingPageScrollValue={landingPageScrollValue}
            top={25}
            right={15}
            // className={upAnimation}
          />
          <LandingPageSkyscraper
            src={Skyscraper1Svg}
            alt="An illustration of a skyscraper"
            left={10}
            height={55}
          />
          <LandingPageSkyscraper
            src={Skyscraper2Svg}
            alt="An illustration of a skyscraper"
            left={30}
            height={30}
          />
          <LandingPageSkyscraper
            src={Skyscraper4Svg}
            alt="An illustration of a skyscraper"
            right={10}
            height={50}
          />
          <LandingPageSkyscraper
            src={Skyscraper3Svg}
            alt="An illustration of a skyscraper"
            right={20}
            height={45}
          />
          <LandingPageMountains
            src={MountainsSvg}
            alt="An illustration of a rolling mountainside"
          />
          <LandingPageCloud
            src={Cloud1Svg}
            alt="An illustration of a cloud"
            // landingPageScrollValue={landingPageScrollValue}
            top={25}
            left={10}
            // className={leftAnimation}
          />
          <LandingPageCloud
            src={Cloud2Svg}
            alt="An illustration of a cloud"
            // landingPageScrollValue={landingPageScrollValue}
            top={5}
            right={45}
            height={2}
            // className={leftAnimation}
          />
          <LandingPageCloud
            src={Cloud3Svg}
            alt="An illustration of a cloud"
            // landingPageScrollValue={landingPageScrollValue}
            top={15}
            right={10}
            // className={rigthAnimation}
          />
          <LandingPageStars
            src={StarsSvg}
            alt="Background image of a starry night sky"
          />
        </LandingPageContainer>

        <MaxWidthContainer
          wideLayout
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Spacer height={6} />
          <section>
            <div tabIndex={0}>
              <h2 data-aos="fade">What we do</h2>
              <Subtext
                data-aos="fade-right"
                data-aos-duration={750}
                data-aos-easing="ease-out-quad"
              >
                We produce award-winning apps and websites for some of the
                biggest brands in the world. Building products that fit you and
                your customer needs is what we do.
              </Subtext>
            </div>
            <Spacer />
            <FourColumnGrid>
              <div data-aos="fade">
                <WhatWeDoCard
                  to="/services"
                  fluidImage={data.robotMagnifyingImage.childImageSharp.fluid}
                  alt="An illustration of a robot looking through a magnifying glass at a screen"
                  title="Research &#38; Design"
                  body="Our design researchers carefully investigate human experience
                and behaviour, curate ways to spark and distill insight, and
                inspire teams and clients to address people’s needs through
                bold, well researched design."
                />
              </div>
              <div
                data-aos="fade"
                // data-aos-delay={projectsCardInColumnTwoDelay}
              >
                <WhatWeDoCard
                  to="/services"
                  fluidImage={data.jigsawImage.childImageSharp.fluid}
                  alt="An illustration of a computer connected by a cable to a jigsaw"
                  title="Development &#38; Testing"
                  body=" Our world class development and QA team refine complex problems
                into simple, human friendly solutions, ensuring your product
                works seamlessly for you and your customers."
                />
              </div>
              <div data-aos="fade">
                <WhatWeDoCard
                  to="/services"
                  fluidImage={data.designPhoneImage.childImageSharp.fluid}
                  alt="An illustration of a mobile phone surrounded by various charts and diagrams"
                  title="Analytics &#38; Optimisation"
                  body="Gain a deep understanding of how your users engage with your
                platform. Effortlessly check the performance of your marketing,
                content, products and more."
                />
              </div>
              <div
                data-aos="fade"
                // data-aos-delay={projectsCardInColumnTwoDelay}
              >
                <WhatWeDoCard
                  to="/services"
                  fluidImage={data.twoRobotsImage.childImageSharp.fluid}
                  alt="An illustration of two retro robots shaking hands"
                  title="Digital Strategy"
                  body="We can help you curate an in-depth business strategy to turn an
                idea into a successful business."
                />
              </div>
            </FourColumnGrid>
          </section>
          <Spacer height={2} />
          <div data-aos="fade">
            <ArrowLink
              to="/services"
              ariaLabel="Clickable link to the services page"
            >
              See all services
            </ArrowLink>
          </div>
          <Spacer height={6} />
          <section>
            <div tabIndex={0}>
              <h2 data-aos="fade">Projects &#38; Partners</h2>
              <Subtext
                data-aos="fade-right"
                data-aos-duration={750}
                data-aos-easing="ease-out-quad"
              >
                We have extensive experience in a wide sector of industries and
                are perfectly placed to craft a digital solution bespoke to you.
              </Subtext>
            </div>
            <Spacer />
            {data.projects.edges.length > 0 && (
              <div data-aos="fade">
                <ProjectCards projects={projects} />
              </div>
            )}
            <Spacer height={2} />
            <div data-aos="fade">
              <ArrowLink
                to="/projects"
                ariaLabel="Clickable link to the projects page"
              >
                See all projects
              </ArrowLink>
            </div>
          </section>
          <Spacer height={6} />
          <OurPartnersLogosSection data={data} />
        </MaxWidthContainer>
        <Spacer height={6} />
        <div ref={contactFormRef} tabIndex={-1}>
          <ContactForm
          // styles={{ height: "calc(100vh - 6rem" }}
          />
        </div>
      </MainIndexPage>
    </Layout>
  );
};
const LandingPageContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 6rem); /* Accounts for Header 6rem */
  display: flex;
  align-items: center;
  justify-content: center;
  // img {
  //   transition: all 1s ease-in-out;
  //   transform: translateY(0);
  //   transform: translateX(0);
  //   &.custom-animation-up {
  //     transform: translateY(-50vh);
  //   }
  //   &.custom-animation-left {
  //     transform: translateX(-60vw);
  //   }
  //   &.custom-animation-right {
  //     transform: translateX(60vw);
  //   }
  // }
  // img[data-aos="custom-animation-up"] {
  //   transition-property: transform;
  //   transform: translateY(0);

  //   &.aos-animate {
  //     transform: translateY(-50vh);
  //   }
  // }
`;

const Subtext = styled.h4`
  color: ${(props) => props.theme.colours.foregroundSecondary};
  font-weight: normal;
  max-width: 40%;
  margin: 2rem 0;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    max-width: 60%;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    max-width: 100%;
  }
`;
const TitleTextContainer = styled.div`
  z-index: 25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colours.foregroundPrimary};
  // padding: 5px;
  // background: ${(props) => props.theme.colours.background};
  // border-radius: ${(props) => props.theme.borderRadius.card};
  margin-top: -6rem; /* Accounts for Header 6rem to center this div */
`;
const LandingPageBlueObject = styled.img<{
  // landingPageScrollValue: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}>`
  position: absolute;
  z-index: 20;
  top: ${(props) => (props.top ? `${props.top}%` : "auto")};
  right: ${(props) => (props.right ? `${props.right}%` : "auto")};
  bottom: ${(props) => (props.bottom ? `${props.bottom}%` : "auto")};
  left: ${(props) => (props.left ? `${props.left}%` : "auto")};
  width: 3rem;
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    width: 2.5rem;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    width: 2rem;
  }
`;
// top: ${(props) =>
//   props.top ? `${props.top - props.landingPageScrollValue}%` : "auto"};
// bottom: ${(props) =>
//   props.bottom ? `${props.bottom - props.landingPageScrollValue}%` : "auto"};
const LandingPageSkyscraper = styled.img<{
  height?: number;
  left?: number;
  right?: number;
}>`
  position: absolute;
  z-index: 15;
  left: ${({ left }) => `${left}%` || "auto"};
  right: ${({ right }) => `${right}%` || "auto"};
  bottom: 0;
  height: ${({ height }) => `${height}%` || "40%"};
  max-height: 500px;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    height: ${({ height }) => (height ? height * 0.65 : 25)}%;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    height: ${({ height }) => (height ? height * 0.5 : 20)}%;
  }
`;
const LandingPageMountains = styled.img`
  position: absolute;
  z-index: 10;
  left: 0;npm ru
  right: 0;
  bottom: 0;
  width: 100vw;
`;
const LandingPageCloud = styled.img<{
  // landingPageScrollValue: number;
  height?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}>`
  position: absolute;
  z-index: 5;
  top: ${(props) => (props.top ? `${props.top}%` : "auto")};
  right: ${(props) => (props.right ? `${props.right}%` : "auto")};
  bottom: ${(props) => (props.bottom ? `${props.bottom}%` : "auto")};
  left: ${(props) => (props.left ? `${props.left}%` : "auto")};
  height: ${({ height }) => (height ? height : 4)}rem;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    height: ${({ height }) =>
      height
        ? height * 0.65
        : 2.5}rem; /* Shrink clouds to avoid Title text as screen shrinks */
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    height: ${({ height }) =>
      height
        ? height * 0.5
        : 2}rem; /* Shrink clouds to avoid Title text as screen shrinks */
  }
`;
// right: ${(props) =>
//   props.right
//     ? `${props.right - props.landingPageScrollValue / 2}%`
//     : "auto"};
// left: ${(props) =>
//   props.left ? `${props.left - props.landingPageScrollValue / 2}%` : "auto"};
const LandingPageStars = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
`;
export const query = graphql`
  {
    robotMagnifyingImage: file(relativePath: { eq: "robot-magnifying.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jigsawImage: file(relativePath: { eq: "jigsaw.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    designPhoneImage: file(relativePath: { eq: "design-phone.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twoRobotsImage: file(relativePath: { eq: "two-robots.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threeBackgroundImage: file(relativePath: { eq: "three-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    storeRoomBackgroundImage: file(
      relativePath: { eq: "store-room-background.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threeLogo: file(relativePath: { eq: "three-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          # GatsbyImageSharpFluid_noBase64 (as opposed to GatsbyImageSharpFluid) removes jarring blur effect on title image
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    smartyLogo: file(relativePath: { eq: "smarty-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    porscheLogo: file(relativePath: { eq: "porsche-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    toyotaConnectedLogo: file(
      relativePath: { eq: "toyota-connected-logo.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zishiLogo: file(relativePath: { eq: "zishi-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zenziumLogo: file(relativePath: { eq: "zenzium-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    myHappyMindLogo: file(relativePath: { eq: "my-happy-mind-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sisuLogo: file(relativePath: { eq: "sisu-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carterCorsonLogo: file(relativePath: { eq: "carter-carson-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sanFranZooLogo: file(relativePath: { eq: "san-fran-zoo-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    storeRoomLogo: file(relativePath: { eq: "store-room-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    # limit projects: allStrapiProjects results to 4, so only display 4 ProjectCards on homepage
    projects: allStrapiProjects(
      sort: { order: DESC, fields: completed_date }
      limit: 4
    ) {
      edges {
        node {
          title_image {
            childImageSharp {
              fluid(maxWidth: 1250) {
                aspectRatio
                sizes
                src
                srcSet
              }
            }
          }
          title
          background_section
          what_we_did_section
          outcomes_section
          project_categories {
            project_category_name
          }
        }
      }
    }
  }
`;
export default HomePage;
