import React, { useState } from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { ToastContainer, toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextArea from "./TextArea";
import TextInput from "./TextInput";
import useForm from "../utils/useForm";
import { Column } from "./FlexContainerStyles";
import Spacer from "./Spacer";
import { Button } from "./Button";

const ContactForm = ({
  styles,
}: {
  styles?: React.CSSProperties;
}): JSX.Element => {
  const {
    inputs,
    handleChange,
    clearForm,
    // , resetForm
  } = useForm({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
    // Note: company is a honeypot field to catch bots only
    company: "",
  });

  const [isSubmitting, setSubmitting] = useState(false);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  } as ToastOptions;

  const notify = (success: boolean, content: string) =>
    success
      ? toast.success(content, toastOptions)
      : toast.error(content, toastOptions);
  return (
    <>
      <FormStyles
        style={styles}
        id="contactForm"
        name="contact"
        method="POST"
        onSubmit={(event) => {
          event.preventDefault();
          // Note: company is a honeypot field to catch bots only
          if (inputs.company === "") {
            setSubmitting(true);
            fetch(`${process.env.STRAPI_API_URL}/requests/email`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                firstName: inputs.firstName,
                lastName: inputs.lastName,
                email: inputs.email,
                subject: inputs.subject,
                message: inputs.message,
              }),
            })
              .then((res) => {
                setSubmitting(false);
                clearForm();
                if (res.status === 200) {
                  notify(true, "Success! Thanks for contacting us");
                } else {
                  notify(false, "Oops! Something went wrong, please try again");
                }
              })
              .catch(() => {
                setSubmitting(false);
                clearForm();
                notify(false, "Oops! Something went wrong, please try again");
              });
          }
        }}
      >
        <div tabIndex={0}>
          <legend aria-label="Complete the following form to contact Blue Beck">
            Get in touch
          </legend>
          <p>
            Help us understand what you need, we’d love to hear about your
            digital ambitions!
            <br />
            Send us a message for an honest conversation with our team of expert
            designers and engineers.
          </p>
        </div>
        <Spacer height={2} />
        <InputGrid>
          <Column>
            <label htmlFor="firstName">First Name</label>
            <TextInput
              required
              id="firstName"
              type="text"
              value={inputs.firstName}
              handleChange={handleChange}
              name={"firstName"}
              aria-label="Text input for your first Name"
            />
          </Column>
          <Column>
            <label htmlFor="lastName">Last Name</label>
            <TextInput
              required
              id="lastName"
              type="text"
              value={inputs.lastName}
              handleChange={handleChange}
              name={"lastName"}
              aria-label="Text input for your last name"
            />
          </Column>
          <Column>
            <label htmlFor="email">Email &#42;</label>
            <TextInput
              required
              id="email"
              type="email"
              value={inputs.email}
              handleChange={handleChange}
              name={"email"}
              aria-label="Text input for your email address required field"
            />
          </Column>
          <Column>
            <label htmlFor="subject">Subject</label>
            <TextInput
              id="subject"
              type="text"
              value={inputs.subject}
              handleChange={handleChange}
              name={"subject"}
              aria-label="Text input for the subject of your message or enquiry"
            />
          </Column>
        </InputGrid>
        <Spacer height={2} />
        <label htmlFor="message">Leave us a message...</label>
        <TextArea
          required
          id="message"
          value={inputs.message}
          handleChange={handleChange}
          name={"message"}
          rows={5}
          aria-label="Text area for your message or enquiry"
        />

        {/* Note: company is a honeypot field to catch bots only */}
        <label htmlFor="company" style={{ display: "none" }}>
          Company
        </label>
        <TextInput
          style={{ display: "none" }}
          id="company"
          type="text"
          value={inputs.company}
          handleChange={handleChange}
          name={"company"}
        />

        {/* <label htmlFor="consent" aria-hidden="true">
      I consent for Blue Beck to store my details for contact purposes
    </label>
    <input
      type="checkbox"
      name="consent"
      id="consent"
      value="True, I consent."
      required
      tabIndex={0}
      aria-label="Checkbox giving consent for Blue Beck to store my details for contact purposes"
    />
    <div className="g-recaptcha" data-sitekey=""></div> */}
        <Spacer height={0.5} />
        <Button type="submit" largeFont disabled={isSubmitting}>
          Submit
        </Button>
        <LoaderContainer>
          {isSubmitting ? (
            <LoaderStyles type="ThreeDots" color={"#FF942A"} height={40} />
          ) : (
            <></>
          )}
        </LoaderContainer>
      </FormStyles>
      <ToastContainer />
    </>
  );
};

const FormStyles = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: auto;
  label,
  legend {
    width: 100%;
    padding: 0;
  }
  p {
    &:first-of-type {
      margin: 2rem 0;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  textarea {
    width: 100%;
    resize: none;
  }
  input,
  textarea {
    border-width: 0 0 2px;
    border-color: ${(props) => props.theme.colours.foregroundSecondary};
    &:focus-visible {
      outline: none;
      border-color: ${(props) => props.theme.colours.orange};
    }
    // Invalid pseudo class ussed to remove ugly red boxes around inputs after form submission - FireFox only
    &:invalid {
      box-shadow: none;
    }
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    padding: 1rem;
    max-width: 400px;
  }
`;
const InputGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4rem;
  width: 100%;
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    display: block;
    label {
      margin-top: 1rem;
    }
  }
`;
const LoaderContainer = styled.div`
  height: 40px;
`;
const LoaderStyles = styled(Loader)`
  max-width: 80px;
  margin: 0 auto;
`;

export default ContactForm;
