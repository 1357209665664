import React from "react";

export default function TextArea({
  id,
  name,
  value,
  required,
  placeholder,
  rows,
  tabIndex,
  ariaLabel,
  handleChange,
}: {
  id: string;
  name: string;
  value: string;
  required?: boolean;
  placeholder?: string;
  rows?: number;
  tabIndex?: number;
  ariaLabel?: string;
  handleChange: (inputName: string, inputValue: string) => void;
}): JSX.Element {
  return (
    <textarea
      id={id}
      value={value}
      required={required || false}
      autoComplete="off"
      autoCapitalize="none"
      placeholder={placeholder}
      rows={rows}
      tabIndex={tabIndex || 0}
      aria-label={ariaLabel}
      onChange={(event) => handleChange(name, event.target.value)}
    />
  );
}
